import React from 'react';
import { Link } from 'react-router-dom';

const Home = () => {
  return (
    <div className="container text-center mt-5">
      <h1>Welcome to NexStore</h1>
      <p className="lead">Manage your shops and products with ease.</p>

      <div className="mt-4">
        <Link to="/login" className="btn btn-primary mx-2">Login</Link>
        <Link to="/signup" className="btn btn-secondary mx-2">Signup</Link>
      </div>

      <div className="mt-5">
        <h2>Features</h2>
        <ul className="list-unstyled">
          <li>🛒 Manage your shops and inventory</li>
          <li>📊 Track analytics and sales</li>
          <li>🌐 Integrated with maps for location-based services</li>
        </ul>
      </div>
    </div>
  );
};

export default Home;

