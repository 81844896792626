import React, { useEffect, useState, useRef } from 'react';
import './slideshow.css'; // Make sure to include your slideshow CSS

const Slideshow = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const slidesRef = useRef(null);

  // Customize these durations as per your requirement
  const slideDuration = 6000; // Duration of each individual slide in milliseconds
  const fadeDuration = 1000;  // Duration of the fading transition in milliseconds
  const enableLog = true;     // Enable log messages for debugging

  const slides = [
    {
      url: "../carousel.jpg",  // Initial URL
      caption: "Find the Best Shops",
      description: "Discover and connect with nearby shops based on your location. We help you find the best places to shop with ease."
    },
    {
      url: "../carousel_2.jpg",  // Updated URL (incremented)
      caption: "Products at Your Fingertips",
      description: "Browse through a variety of products from different shops, all in one place. Shop smart and shop local."
    },
    {
      url: "../carousel_3.jpg",  // Updated URL (incremented)
      caption: "Support Local Businesses",
      description: "Help local businesses grow by shopping from nearby stores. Find unique products and special offers."
    },
    {
      url: "../carousel_4.jpg",  // Updated URL (incremented)
      caption: "Shop with Confidence",
      description: "Shop from verified and trusted stores. We ensure you get the best shopping experience possible."
    },
    {
      url: "../carousel_5.jpg",  // Updated URL (incremented)
      caption: "Get Exclusive Discounts",
      description: "Unlock exclusive deals and discounts by shopping locally. Support your community and save money!"
    },
  ];

  useEffect(() => {
    // Slideshow logic
    const intervalId = setInterval(() => {
      setCurrentSlide((prevSlide) => {
        const nextSlide = (prevSlide + 1) % slides.length;
        if (enableLog) console.log(`Showing slide ${nextSlide}`);
        return nextSlide;
      });
    }, slideDuration);

    return () => clearInterval(intervalId); // Cleanup the interval on component unmount
  }, [slideDuration, slides.length, enableLog]);

  return (
    <div id="slides" ref={slidesRef}>
      {slides.map((slide, index) => (
        <div
          key={index}
          className={`slide ${currentSlide === index ? 'active' : ''}`}
          style={{
            backgroundImage: `url(${slide.url})`,
            opacity: currentSlide === index ? 1 : 0,
            transition: `opacity ${fadeDuration}ms ease-in-out`,
          }}
        >
          <div className="static-content">
            <h1>{slide.caption}</h1>
            <p>{slide.description}</p> {/* Add description here */}
          </div>
        </div>
      ))}
    </div>
  );
};

export default Slideshow;
