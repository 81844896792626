import api from '../api/api';
import React, { useState, useEffect } from 'react';
import ProductDetails from './ProductDetailsModal';
import { Link } from 'react-router-dom';

const ShopView = () => {
  const [shops, setShops] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);  // Product selected for modal
  const [showModal, setShowModal] = useState(false);

  // Fetch the shops from the API
  useEffect(() => {
    const fetchShops = async () => {
      try {
        const response = await api.get('api/shops');
        setShops(response.data.shops);
      } catch (error) {
        console.error('Error fetching shops:', error);
      }
    };
    fetchShops();
  }, []);

  const handleProductClick = (product) => {
    setSelectedProduct(product);
    setShowModal(true);
  };

  return (
    <div className="container mt-5">
      <h2>Shops and Products</h2>
      {shops.length === 0 ? (
        <p>No shops available.</p>
      ) : (
        shops.map((shop) => (
          <div key={shop.id} className="shop-card mb-4">
            <h3>{shop.name}</h3>
            <p>{shop.address}</p>
            <h4>Products</h4>
            <ul className="list-group">
              {shop.products && shop.products.length > 0 ? (
                shop.products.map((product) => (
                  <li key={product.id} className="list-group-item">
                    {product.name} - {product.price}
                    <button
                      className="btn btn-primary btn-sm float-right"
                      onClick={() => handleProductClick(product)}
                    >
                      View Details
                    </button>
                  </li>
                ))
              ) : (
                <p>No products available for this shop.</p>
              )}
            </ul>
            <Link to={`/shops/${shop.id}`}>View Shop Details</Link>
          </div>
        ))
      )}

      {/* Modal for product details */}
      {showModal && (
        <ProductDetails
          product={selectedProduct}
          onClose={() => setShowModal(false)}
        />
      )}
    </div>
  );
};

export default ShopView;

