import React, { useState } from 'react';
import api from '../../api/api'; // Adjust the import path
import './CreateShop.css';
import { jwtDecode } from 'jwt-decode'; // Ensure jwtDecode is properly imported

const CreateShop = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const totalSteps = 3;

  const [formData, setFormData] = useState({
    name: '',
    contactNumber: '',
    legalBusinessName: '',
    postalCode: '',
    address: '',
    latitude: '',
    longitude: '',
    currency: '',
  });
  const [locationStatus, setLocationStatus] = useState(null);

  const handleNext = () => {
    setCurrentStep((prevStep) => prevStep + 1);
  };

  const handlePrevious = () => {
    setCurrentStep((prevStep) => prevStep - 1);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem('token');
      let userId = null;

      if (token) {
        const decodedToken = jwtDecode(token);
        userId = decodedToken.id;
      }

      const payload = { ...formData, userId };
      await api.post('/api/shops', payload);
      alert('Shop created successfully!');
      window.location.href = '/dashboard';
    } catch (error) {
      console.error('Error creating shop:', error);
      alert('Failed to create shop.');
    }
  };

  // Function to get the user's current location
  const getCurrentLocation = () => {
    if (navigator.geolocation) {
      setLocationStatus('Getting current location...');
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setFormData((prevData) => ({
            ...prevData,
            latitude,
            longitude,
          }));
          setLocationStatus('Location retrieved successfully!');
        },
        (error) => {
          console.error('Error getting location:', error);
          setLocationStatus('Failed to retrieve location.');
        }
      );
    } else {
      setLocationStatus('Geolocation is not supported by this browser.');
    }
  };

  // Calculate progress percentage
  const progressPercentage = (currentStep / totalSteps) * 100;

  return (
    <div id="msform">
      {/* Progress bar */}
      <div className="progress">
        <div
          className="progress-bar"
          role="progressbar"
          style={{ width: `${progressPercentage}%` }}
          aria-valuenow={progressPercentage}
          aria-valuemin="0"
          aria-valuemax="100"
        >
          {Math.round(progressPercentage)}%
        </div>
      </div>

      {/* Form */}
      <form onSubmit={handleSubmit}>
        {/* Step 1: Shop Details */}
        {currentStep === 1 && (
          <fieldset className="active">
            <h2 className="fs-title">Enter Shop Details</h2>
            <input
              type="text"
              className="auth-button"
              name="name"
              placeholder="Shop Name"
              value={formData.name}
              onChange={handleInputChange}
              required
            />
            <input
              type="text"
              className="auth-button"
              name="contactNumber"
              placeholder="Contact Number (e.g., +123456789)"
              value={formData.contactNumber}
              onChange={handleInputChange}
              required
            />
            <input
              type="text"
              className="auth-button"
              name="legalBusinessName"
              placeholder="Legal Business Name (Optional)"
              value={formData.legalBusinessName}
              onChange={handleInputChange}
            />
            <input
              type="text"
              className="auth-button"
              name="postalCode"
              placeholder="Postal Code (Optional)"
              value={formData.postalCode}
              onChange={handleInputChange}
            />
            <input 
              type="text"
              className="auth-button"
              placeholder="Currency"
              name="currency"
              value={formData.currency}
              onChange={handleInputChange}
              />
            <button type="button" className="next action-button" onClick={handleNext}>
              Next
            </button>
          </fieldset>
        )}

        {/* Step 2: Location */}
        {currentStep === 2 && (
          <fieldset className="active">
            <h2 className="fs-title">Enter Location</h2>
            <input
              type="text"
              className="auth-button"
              name="address"
              placeholder="Shop Address"
              value={formData.address}
              onChange={handleInputChange}
              required
            />
            <button
              type="button"
              className="auth-button"
              onClick={getCurrentLocation}
            >
              Get Current Location
            </button>
            {locationStatus && <p>{locationStatus}</p>}
            <input
              type="text"
              className="auth-button"
              name="latitude"
              placeholder="Latitude"
              value={formData.latitude}
              onChange={handleInputChange}
              required
              readOnly
            />
            <input
              type="text"
              className="auth-button"
              name="longitude"
              placeholder="Longitude"
              value={formData.longitude}
              onChange={handleInputChange}
              required
              readOnly
            />
            <button type="button" className="previous action-button" onClick={handlePrevious}>
              Previous
            </button>
            <button type="button" className="next action-button" onClick={handleNext}>
              Next
            </button>
          </fieldset>
        )}

        {/* Step 3: Confirmation */}
        {currentStep === 3 && (
          <fieldset className="active">
            <h2 className="fs-title">Confirm Shop Details</h2>
            <div className="confirmation-card">
              <ul className="confirmation-list">
                <li><strong>Shop Name:</strong> {formData.name}</li>
                <li><strong>Contact Number:</strong> {formData.contactNumber}</li>
                <li><strong>Legal Business Name:</strong> {formData.legalBusinessName || 'N/A'}</li>
                <li><strong>Postal Code:</strong> {formData.postalCode || 'N/A'}</li>
                <li><strong>Address:</strong> {formData.address}</li>
                <li><strong>Latitude:</strong> {formData.latitude}</li>
                <li><strong>Longitude:</strong> {formData.longitude}</li>
                <li><strong>Currency:</strong> {formData.currency}</li>
              </ul>
            </div>
            <button type="button" className="previous action-button" onClick={handlePrevious}>
              Previous
            </button>
            <button type="submit" className="submit action-button">
              Submit
            </button>
          </fieldset>
        )}
      </form>
    </div>
  );
};

export default CreateShop;
