import React, { useState, useEffect } from 'react';
import CreateProductForm from './CreateProduct';
import api from '../api/api';
import './Dashboard.css'; // Assuming you have some styling in this file

// Utility function to map currency codes to symbols
const getCurrencySymbol = (currencyCode) => {
  const currencyMap = {
    USD: '$', // US Dollar
    EUR: '€', // Euro
    GBP: '£', // British Pound
    NGN: '₦', // Nigerian Naira
    JPY: '¥', // Japanese Yen
    INR: '₹', // Indian Rupee
    // Add more currency symbols as needed
  };
  return currencyMap[currencyCode] || currencyCode; // Return symbol or code if not found
};

const ShopDetails = ({ shop, onClose }) => {
  const [shopDetails, setShopDetails] = useState(shop);
  const [products, setProducts] = useState([]);
  const [message, setMessage] = useState('');
  const [editingProduct, setEditingProduct] = useState(null); // For tracking the product being edited
  const [newImage, setNewImage] = useState(null); // For uploading a new product image

  // Fetch products for the selected shop
  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await api.get(`/api/products/shop/${shop.id}`);
        setProducts(response.data.products);
      } catch (error) {
        setMessage('Error fetching products');
      }
    };
    fetchProducts();
  }, [shop.id]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setShopDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  const handleUpdateShop = async () => {
    try {
      await api.put(`/api/shops/${shop.id}`, shopDetails);
      setMessage('Shop details updated successfully!');
    } catch (error) {
      setMessage('Error updating shop details');
    }
  };

  const handleDeleteShop = async () => {
    try {
      await api.delete(`/api/shops/${shop.id}`);
      alert('Shop deleted successfully');
      onClose();
    } catch (error) {
      alert('Error deleting shop');
    }
  };

  const handleEditProduct = (product) => {
    setEditingProduct(product); // Set the selected product for editing
  };

  const handleUpdateProduct = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData();
      formData.append('name', editingProduct.name);
      formData.append('price', editingProduct.price);
      formData.append('stock', editingProduct.stock);
      formData.append('description', editingProduct.description);
      if (newImage) {
        formData.append('image', newImage); // Append new image if selected
      }

      await api.put(`/api/products/${editingProduct.id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      setMessage('Product updated successfully!');
      setEditingProduct(null); // Clear edit form after update
    } catch (error) {
      setMessage('Error updating product');
    }
  };

  const handleDeleteProduct = async (productId) => {
    try {
      await api.delete(`/api/products/${productId}`);
      setProducts(products.filter((product) => product.id !== productId));
      setMessage('Product deleted successfully!');
    } catch (error) {
      setMessage('Error deleting product');
    }
  };

  const handleProductInputChange = (e) => {
    const { name, value } = e.target;
    setEditingProduct((prevProduct) => ({
      ...prevProduct,
      [name]: value,
    }));
  };

  const handleImageChange = (e) => {
    setNewImage(e.target.files[0]); // Set the new image for upload
  };

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <span className="close-button" onClick={onClose}>&times;</span>
        <h2 className="title">Edit Shop: {shop.name}</h2>

        {/* Shop Editing Form */}
        <label htmlFor="shopName">Shop Name</label>
        <input
          type="text"
          className="auth-button"
          name="name"
          id="shopName"
          value={shopDetails.name}
          onChange={handleInputChange}
          placeholder="Shop Name"
        />
        <label htmlFor="shopAddress">Shop Address</label>
        <input
          type="text"
          className="auth-button"
          name="address"
          id="shopAddress"
          value={shopDetails.address}
          onChange={handleInputChange}
          placeholder="Shop Address"
        />
        <label htmlFor="contactNumber">Contact Number</label>
        <input
          type="text"
          className="auth-button"
          name="contactNumber"
          id="contactNumber"
          value={shopDetails.contactNumber}
          onChange={handleInputChange}
          placeholder="Contact Number"
        />
        <label htmlFor="currency">Currency</label>
        <input
          type="text"
          className="auth-button"
          name="currency"
          id="currency"
          value={shopDetails.currency}
          onChange={handleInputChange}
          placeholder="Currency"
        />
        <button onClick={handleUpdateShop} className="auth-button">Save Changes</button>
        <span className="space">  </span>
        <button onClick={handleDeleteShop} className="auth-button">Delete Shop</button>

        {message && <p>{message}</p>}

        <h3 className="mt-4">Create a Product</h3>
        <CreateProductForm shopId={shop.id} />
        
        {/* Product Section */}
        <h3 className="product-ti">Products in {shop.name}</h3>
        {products.length > 0 ? (
          <div className="product-cards-container">
            {products.map((product) => (
              <div key={product.id} className="product-card">
                <div className="product-image-column">
                  <img src={product.imageUrl} alt={product.name} className="product-image" />
                </div>
                <div className="product-details-column">
                  <h4 className="product-ti">{product.name}</h4>
                  <p className="product-price">Price: <span>{getCurrencySymbol(shopDetails.currency)} {product.price}</span></p>
                  <p className="product-stock">Stock: {product.stock}</p>
                  <p className="product-des">{product.description}</p>
                  <button onClick={() => handleEditProduct(product)} className="auth-button">Edit</button>
                  <span className="space">  </span>
                  <button onClick={() => handleDeleteProduct(product.id)} className="auth-button">Delete</button>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <p>No products available for this shop.</p>

        )}

        {/* Product Edit Modal */}
        {editingProduct && (
          <div className="modal-overlay">
            <div className="modal-content">
              <span className="close-button" onClick={() => setEditingProduct(null)}>&times;</span>
              <h2>Edit Product: {editingProduct.name}</h2>
              <form onSubmit={handleUpdateProduct}>
                <label htmlFor="productName">Product Name</label>
                <input
                  type="text"
                  className="auth-button"
                  name="name"
                  id="productName"
                  value={editingProduct.name}
                  onChange={handleProductInputChange}
                  placeholder="Product Name"
                />
                <label htmlFor="productPrice">Product Price</label>
                <input
                  type="text"
                  className="auth-button"
                  name="price"
                  id="productPrice"
                  value={editingProduct.price}
                  onChange={handleProductInputChange}
                  placeholder="Product Price"
                />
                <label htmlFor="productStock">Product Stock</label>
                <input
                  type="text"
                  className="auth-button"
                  name="stock"
                  id="productStock"
                  value={editingProduct.stock}
                  onChange={handleProductInputChange}
                  placeholder="Stock"
                />
                <label htmlFor="productDescription">Description</label>
                <textarea
                  className="auth-button"
                  name="description"
                  id="productDescription"
                  value={editingProduct.description}
                  onChange={handleProductInputChange}
                  placeholder="Product Description"
                />
                <label htmlFor="productImage">Product Image</label>
                <input
                  type="file"
                  className="auth-button"
                  name="image"
                  id="productImage"
                  onChange={handleImageChange}
                />
                <button type="submit" className="auth-button">Save Changes</button>
              </form>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ShopDetails;
