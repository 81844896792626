import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import api from '../api/api';

const ProductDetailsModal = ({ product, onClose }) => {
  const handleNotification = async () => {
  try {
    const response = await api.post('/api/notifications', {
      productId: product.id,
      message: `A user is interested in your product: ${product.name}`,
    });
    console.log('Notification response:', response);
    alert('Notification sent to the shop owner.');
  } catch (error) {
    console.error('Error sending notification:', error);
    alert('Error sending notification.');
  }
};

  return (
    <Modal show={true} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>{product.name}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p><strong>Price:</strong> {product.price}</p>
        <p><strong>Description:</strong> {product.description}</p>
        <img
          src={product.imageUrl}
          alt={product.name}
          style={{ width: '100%', height: 'auto' }}
        />
      </Modal.Body>
      <Modal.Footer>
        {localStorage.getItem('token') && (
          <Button variant="primary" onClick={handleNotification}>
            Notify Shop Owner
          </Button>
        )}
        <Button variant="secondary" onClick={onClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ProductDetailsModal;

