import React, { useState } from 'react';
import api from '../../api/api';
import './Auth.css';


const Signup = () => {
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');

  const handleSignup = async (e) => {
    e.preventDefault();
    try {
      await api.post('/api/users/signup', { username, email, password });
      setMessage('Signup successful! You can now log in.');
    } catch (error) {
      setMessage('Error: ' + (error.response?.data?.message || 'Signup failed.'));
    }
  };

  return (
    <div className="auth-container">
      <h2 className="auth-heading">Sign Up</h2>
      <form onSubmit={handleSignup} className="auth-form">
        <input
          type="text"
          placeholder="Username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          required
        />
        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        <button type="submit" className="auth-button">Sign Up</button>
        <a href="/login" className="auth-button">Login</a>
        {message && <p className="auth-success">{message}</p>}
      </form>
    </div>
  );
};

export default Signup;
