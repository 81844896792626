import React, { useState, useEffect } from 'react';
import api from '../api/api';
import { Button } from 'react-bootstrap';
import './profile.css';

const Profile = () => {
  const [user, setUser] = useState(null);
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [verificationToken, setVerificationToken] = useState('');

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const response = await api.get('/api/users/profile');
        setUser(response.data);
      } catch (error) {
        setError('Failed to fetch user profile.');
      }
    };

    fetchUserProfile();
  }, []);

  const handleEmailVerification = async () => {
    try {
      await api.post('/api/auth/send-verification-email', { email: user.email });
      setMessage('Verification email sent successfully.');
    } catch (error) {
      setMessage('Error sending verification email.');
    }
  };

  const handleVerifyEmail = async () => {
    try {
      await api.post('/api/auth/verify-email', { token: verificationToken });
      setMessage('Email verified successfully.');
      setShowModal(false);  // Close modal on success
    } catch (error) {
      setMessage('Error verifying email.');
    }
  };

  if (error) {
    return <div className="container mt-5 text-center"><p className="text-danger">{error}</p></div>;
  }

  if (!user) {
    return <div className="container mt-5 text-center"><p>Loading profile...</p></div>;
  }

  return (
    <div className="auth-container mt-5">
      <h2 className="auth-heading">User Profile</h2>
      <div className="card auth-card mt-4">
        <div className="card-body">
          <div className="profile-info">
            <div className="info-item">
              <p className="card-title">Username:</p>
              <p className="ti">{user.username}</p>
            </div>
            <div className="info-item">
              <p className="card-title">Email:</p>
              <p className="ti">{user.email}</p>
            </div>
            <div className="info-item">
              <p className="card-title">User ID:</p>
              <p className="ti">{user.id}</p>
            </div>
            <div className="info-item">
              <p className="ti">
                {user.isVerified ? 'Verified Account' : 'Verification Pending. Please verify your email.'}
              </p>
            </div>
          </div>

          {/* Resend verification email */}
          {!user.isVerified && (
            <div className="ver">
              <Button className="auth-button" onClick={handleEmailVerification}>
                Resend Verification Email
              </Button>
              <Button className="auth-button ml-2" onClick={() => setShowModal(true)}>
                Verify Email
              </Button>
            </div>
          )}
        </div>
      </div>

      {/* Modal for entering the verification token */}
      {showModal && (
        <div className="custom-modal-backdrop">
          <div className="custom-modal">
            <div className="custom-modal-header">
              <h3>Email Verification</h3>
              <button className="close-button" onClick={() => setShowModal(false)}>X</button>
            </div>
            <div className="custom-modal-body">
              <p>Enter the verification token you received in your email:</p>
              <input
                type="text"
                className="verification-input"
                placeholder="Enter verification token"
                value={verificationToken}
                onChange={(e) => setVerificationToken(e.target.value)}
              />
            </div>
            <div className="custom-modal-footer">
              <button className="auth-button" onClick={handleVerifyEmail}>
                Verify Email
              </button>
              <button className="cancel-button" onClick={() => setShowModal(false)}>
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
      {message && <p className="auth-success">{message}</p>}
    </div>
  );
};

export default Profile;
