import React, { useState } from 'react';
import api from '../api/api';
import './Dashboard.css';

const CreateProductForm = ({ shopId, product = null }) => {
  const [formData, setFormData] = useState({
    name: product ? product.name : '',
    description: product ? product.description : '',
    price: product ? product.price : '',
    stock: product ? product.stock : '',
    imageUrl: product ? product.imageUrl : null,
    imageFile: null,
  });
  const [preview, setPreview] = useState(null);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFormData((prevData) => ({
      ...prevData,
      imageFile: file,
    }));
    setPreview(URL.createObjectURL(file));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formDataObj = new FormData();
    formDataObj.append('name', formData.name);
    formDataObj.append('description', formData.description);
    formDataObj.append('price', formData.price);
    formDataObj.append('stock', formData.stock);
    formDataObj.append('shopId', shopId);
    if (formData.imageFile) {
      formDataObj.append('image', formData.imageFile);
    }

    try {
      const url = product ? `/api/products/${product.id}` : '/api/products';
      const response = product
        ? await api.put(url, formDataObj)
        : await api.post(url, formDataObj);
      alert('Product saved successfully!');
    } catch (error) {
      alert('Error saving product');
    }
  };

  const handleDeleteProduct = async () => {
    if (!product) return;
    try {
      await api.delete(`/api/products/${product.id}`);
      alert('Product deleted successfully');
    } catch (error) {
      alert('Error deleting product');
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit} className="product-form">
        <input
          type="text"
          name="name"
          value={formData.name}
          onChange={handleInputChange}
          placeholder="Product Name"
          required
        />
        <input
          type="text"
          name="description"
          value={formData.description}
          onChange={handleInputChange}
          placeholder="Product Description"
          required
        />
        <input
          type="number"
          name="price"
          value={formData.price}
          onChange={handleInputChange}
          placeholder="Price"
          required
        />
        <input
          type="number"
          name="stock"
          value={formData.stock}
          onChange={handleInputChange}
          placeholder="Stock"
          required
        />
        <input type="file" name="image" onChange={handleFileChange} />
        {preview && <img src={preview} alt="Product preview" className="preview-img" />}

        <button type="submit" className="action-button">Save Product</button>
        {product && (
          <button type="button" className="delete-button" onClick={handleDeleteProduct}>
            Delete Product
          </button>
        )}
      </form>
    </div>
  );
};

export default CreateProductForm;
