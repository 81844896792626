import React, { useState } from 'react';
import api from '../../api/api';
import './Auth.css';


const Login = ({ setAuth }) => {
  const [identifier, setIdentifier] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await api.post('/api/users/login', { identifier, password });
      localStorage.setItem('token', response.data.token);
      setAuth(true); // Set authentication state
      window.location.href = '/profile';
    } catch (error) {
      setErrorMessage('Login failed. Please check your credentials.');
    }
  };

  return (
    <div className="auth-container">
      <h2 className="auth-heading">Login</h2>
      <form onSubmit={handleLogin} className="auth-form">
        <input
          type="text"
          placeholder="Email, Username, or User ID"
          value={identifier}
          onChange={(e) => setIdentifier(e.target.value)}
          required
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        <button type="submit" className="auth-button">Login</button>
        <a href="/signup" className="auth-button">Sign Up</a>
        {errorMessage && <p className="auth-error">{errorMessage}</p>}
        <a href="/forgot-password" className="auth-link">Forgot Password?</a>
      </form>
    </div>
  );
};

export default Login;
