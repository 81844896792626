import React, { useState } from 'react';
import api from '../../api/api';

const CreateProduct = ({ shopId }) => {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [price, setPrice] = useState('');
  const [stock, setStock] = useState('');
  const [image, setImage] = useState(null);
  const [message, setMessage] = useState('');

  const handleCreateProduct = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('name', name);
    formData.append('description', description);
    formData.append('price', price);
    formData.append('stock', stock);
    formData.append('image', image);
    formData.append('shopId', shopId);

    try {
      await api.post('/api/products', formData);
      setMessage('Product created successfully!');
    } catch (error) {
      setMessage('Error: ' + error.response?.data?.message || 'Failed to create product.');
    }
  };

  return (
    <div className="container mt-5">
      <div className="row justify-content-center">
        <div className="col-md-6">
          <h2 className="text-center mb-4">Create Product</h2>
          <form onSubmit={handleCreateProduct}>
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                placeholder="Product Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
            </div>
            <div className="form-group">
              <textarea
                className="form-control"
                placeholder="Description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                required
              />
            </div>
            <div className="form-group">
              <input
                type="number"
                className="form-control"
                placeholder="Price"
                value={price}
                onChange={(e) => setPrice(e.target.value)}
                required
              />
            </div>
            <div className="form-group">
              <input
                type="number"
                className="form-control"
                placeholder="Stock"
                value={stock}
                onChange={(e) => setStock(e.target.value)}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="image">Upload Image</label>
              <input
                type="file"
                className="form-control-file"
                onChange={(e) => setImage(e.target.files[0])}
                required
              />
            </div>
            <button type="submit" className="btn btn-primary btn-block">Create Product</button>
          </form>
          {message && <p className="text-success text-center mt-3">{message}</p>}
        </div>
      </div>
    </div>
  );
};

export default CreateProduct;
