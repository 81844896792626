import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Login from './components/Auth/Login';
import Signup from './components/Auth/Signup';
import CreateShop from './components/Shop/CreateShop';
import CreateProduct from './components/Product/CreateProduct';
import Home from './pages/Home';
import Profile from './pages/Profile';  // Import the Profile page
import LandingPage from './components/LandingPage';
import Dashboard from './components/Dashboard';
import ShopView from './components/ShopView';
import './index.css';
import Footer from './components/footer';
import Forgot from './components/Auth/forgot';
import ProductDetailsModal from './components/ProductDetailsModal';
import NotFound from './components/NotFound';

const App = () => {
  const [isAuthenticated, setAuth] = useState(!!localStorage.getItem('token'));
  const [loading, setLoading] = useState(true); // Add loading state

  // Simulate resource or data loading (e.g., API or assets)
  useEffect(() => {
    const loadResources = async () => {
      // Simulate loading delay (e.g., fetching initial data or waiting for components to mount)
      setTimeout(() => {
        setLoading(false); // Disable loading after delay (or after API fetch is done)
      }, 2000); // Adjust the delay as needed
    };

    loadResources();
  }, []);

  const handleLogout = () => {
    localStorage.removeItem('token');
    setAuth(false);
  };

  // Render loading spinner until all components/resources are ready
  if (loading) {
    return (
      <div className="loading-container">
        <div className="spinner"></div>
        <p>Loading, please wait...</p>
      </div>
    );
  }

  return (
    <div className="body">
      <Router>
        <header>
          <div className="nav-wrapper">
            <div className="logo-container">
              <a href="/">
                <img className="logo" src="./logo.png" alt="Logo" />
              </a>
              <a href="/">Nextore</a>
            </div>
            <nav>
              <input className="hidden" type="checkbox" id="menuToggle" />
              <label className="menu-btn" htmlFor="menuToggle">
                <div className="menu"></div>
                <div className="menu"></div>
                <div className="menu"></div>
              </label>
              <div className="nav-container">
                <ul className="nav-tabs">
                  {isAuthenticated ? (
                    <>
                      <li className="nav-tab">
                        <a href="/create-shop">Create Shop</a>
                      </li>
                      <li className="nav-tab">
                        <a href="/profile">Profile</a>
                      </li>
                      <li className="nav-tab">
                        <a href="/dashboard">Dashboard</a>
                      </li>
                      <li className="nav-tab">
                        <button className="btn-logout" onClick={handleLogout}>Logout</button>
                      </li>
                    </>
                  ) : (
                    <>
                      <li className="nav-tab">
                        <a href="/login">Login</a>
                      </li>
                      <li className="nav-tab">
                        <a href="/signup">Signup</a>
                      </li>
                    </>
                  )}
                </ul>
              </div>
            </nav>
          </div>
        </header>
        <Routes>
          <Route path="/login" element={<Login setAuth={setAuth} />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/create-shop" element={isAuthenticated ? <CreateShop /> : <Login setAuth={setAuth} />} />
          <Route path="/create-product/:shopId" element={isAuthenticated ? <CreateProduct /> : <Login setAuth={setAuth} />} />
          <Route path="/profile" element={isAuthenticated ? <Profile /> : <Login setAuth={setAuth} />} />
          <Route path="/home" element={<Home />} />
          <Route path="/" element={<LandingPage />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/shops" element={<ShopView />} />
          <Route path="/forgot-password" element={<Forgot />} />
          <Route path="/product/:productId/edit" element={<ProductDetailsModal />} />
	  <Route path="*" element={<NotFound />} />
        </Routes>
        <Footer />
      </Router>
    </div>
  );
};

export default App;
