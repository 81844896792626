import React, { useState, useEffect } from 'react';
import api from '../api/api';
import ShopDetailsModal from './ShopDetails';
import './Dashboard.css';
import { jwtDecode } from 'jwt-decode';

const Dashboard = () => {
  const [shops, setShops] = useState([]);
  const [selectedShop, setSelectedShop] = useState(null);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const fetchShops = async () => {
      try {
        const token = localStorage.getItem('token'); // Get the JWT token from local storage

        if (token) {
          // Decode the token to get the user ID
          const decodedToken = jwtDecode(token);
          const userId = decodedToken.id; // Extract the user ID from the token

          // Make an API call to get the shops for the user
          const response = await api.get(`/api/shops/shops/user/${userId}`);

          // Set the shops data
          setShops(response.data.shops);
        } else {
          console.error('Token not found');
        }
      } catch (error) {
        console.error('Error fetching shops:', error);
      }
    };

    fetchShops();
  }, []);

  const handleSelectShop = (shop) => {
    setSelectedShop(shop);
    setShowModal(true);
  };

  return (
    <div className="dashboard">
      <h2>Shop Owner Dashboard</h2>
      <div className="shop-list">
        {shops.map((shop) => (
          <div className="shop-card" key={shop.id} onClick={() => handleSelectShop(shop)}>
            <h3>{shop.name}</h3>
            <p>{shop.address}</p>
            <p>Contact: {shop.contactNumber}</p>
          </div>
        ))}
      </div>

      {showModal && selectedShop && (
        <ShopDetailsModal
          shop={selectedShop}
          onClose={() => setShowModal(false)}
          onProductCreate={() => {
          }}
        />
      )}
    </div>
  );
};

export default Dashboard;
