
import React, { useState } from 'react';
import api from '../../api/api';
import './Auth.css';

const Forgot = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleForgotPassword = async (e) => {
    e.preventDefault();
    try {
      await api.post('/api/auth/request-password-reset', { email });
      setMessage('Password reset instructions sent to your email.');
    } catch (error) {
      setMessage('Error: ' + (error.response?.data?.message || 'Request failed.'));
    }
  };

  return (
    <div className="auth-container">
      <h2 className="auth-heading">Forgot Password</h2>
      <form onSubmit={handleForgotPassword} className="auth-form">
        <input
          type="email"
          placeholder="Enter your email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <button type="submit" className="auth-button">Submit</button>
        {message && <p className="auth-success">{message}</p>}
      </form>
    </div>
  );
};

export default Forgot;
