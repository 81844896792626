import React from 'react';
import MapView from './MapView';
import '../index.css';
import Slideshow from './slideshow';

const LandingPage = () => {
  return (
    <div class="body">
      <Slideshow />
      <MapView />
    </div>
  );
};

export default LandingPage;

