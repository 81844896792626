import React, { useEffect, useRef, useState } from 'react';
import mapboxgl from 'mapbox-gl';
import MapboxDirections from '@mapbox/mapbox-gl-directions/dist/mapbox-gl-directions';
import api from '../api/api';
import 'mapbox-gl/dist/mapbox-gl.css';
import '@mapbox/mapbox-gl-directions/dist/mapbox-gl-directions.css';
import '../index.css';
import './map.css';

const MapView = () => {
    const mapRef = useRef(null); // Reference to the map instance
    const [shops, setShops] = useState([]); // Stores shop data
    const [filteredShops, setFilteredShops] = useState([]); // Filtered shop data
    const [searchQuery, setSearchQuery] = useState(''); // Search query for shops and products
    const [userLocation, setUserLocation] = useState(null); // Stores user location
    const MAPBOX_ACCESS_TOKEN = 'pk.eyJ1Ijoic2FtdG9iMjIiLCJhIjoiY20xYWRiNTk2MWZ1cTJsc2NoenJydXh6MCJ9.08hr7Fjl1i0O56xCOZlJyQ';
    const [mapStyle, setMapStyle] = useState('mapbox://styles/mapbox/streets-v11'); // Map style state

    // Initialize the map once
    useEffect(() => {
        if (!mapRef.current) {
            mapboxgl.accessToken = MAPBOX_ACCESS_TOKEN;

            // Initialize Mapbox map
            const map = new mapboxgl.Map({
                container: 'map', // container ID
                style: mapStyle, // initial map style
                center: [-0.09, 51.505], // Initial center (London)
                zoom: 13, // Initial zoom level
                projection: 'globe', // Set the projection to globe
            });

            // Enable 3D terrain
            map.addControl(new mapboxgl.AttributionControl(), 'bottom-right');

            // Store map instance
            mapRef.current = map;

            // Add directions control to the map
            const directions = new MapboxDirections({
                accessToken: MAPBOX_ACCESS_TOKEN,
                unit: 'metric',
                profile: 'mapbox/driving',
            });
            map.addControl(directions, 'top-left');

            // Add layer switcher
            const layerSwitcher = document.createElement('div');
            layerSwitcher.className = 'mapboxgl-ctrl';
            layerSwitcher.innerHTML = 'Layers';
            layerSwitcher.onclick = () => {
                // Toggle layer logic
            };
            map.addControl(layerSwitcher, 'top-right');
        }
    }, [mapStyle]);

    // Update map style when user selects a new style
    useEffect(() => {
        if (mapRef.current) {
            mapRef.current.setStyle(mapStyle);
        }
    }, [mapStyle]);

    // Fetch shops from the API
    useEffect(() => {
        const fetchShops = async () => {
            try {
                const response = await api.get('api/shops');
                setShops(response.data.shops);
                setFilteredShops(response.data.shops); // Initialize filtered shops
            } catch (error) {
                console.error('Error fetching shops:', error);
            }
        };
        fetchShops();
    }, []);

    // Filter shops based on the search query
    useEffect(() => {
        const filtered = shops.filter((shop) =>
            shop.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
            shop.products.some((product) =>
                product.name.toLowerCase().includes(searchQuery.toLowerCase())
            )
        );
        setFilteredShops(filtered);
    }, [searchQuery, shops]);

    // Add filtered shops as markers on the map
    useEffect(() => {
        if (mapRef.current && filteredShops.length > 0) {
            const bounds = new mapboxgl.LngLatBounds();

            filteredShops.forEach((shop) => {
                // Create a shop marker
                const shopMarker = new mapboxgl.Marker({ color: 'black' })
                    .setLngLat([shop.longitude, shop.latitude])
                    .setPopup(new mapboxgl.Popup().setHTML(`
                        <strong>${shop.name}</strong><br>
                        Address: ${shop.address}<br>
                        <a href="/shop/${shop._id}">Explore</a>
                    `))
                    .addTo(mapRef.current);

                // Add to map bounds
                bounds.extend([shop.longitude, shop.latitude]);

                // Add click event to generate route
                shopMarker.getElement().addEventListener('click', () => {
                    if (userLocation) {
                        const directions = new MapboxDirections({
                            accessToken: MAPBOX_ACCESS_TOKEN,
                            unit: 'metric',
                            profile: 'mapbox/driving',
                        });
                        directions.setOrigin(userLocation);
                        directions.setDestination([shop.longitude, shop.latitude]);
                        mapRef.current.addControl(directions);
                    }
                });
            });

            // Fit the map to show all shops if no user location
            if (!userLocation) {
                mapRef.current.fitBounds(bounds, { padding: 50 });
            }
        }
    }, [filteredShops, userLocation]);

    // Track user location and update the map
    useEffect(() => {
        if (mapRef.current) {
            if ("geolocation" in navigator) {
                navigator.geolocation.getCurrentPosition(
                    (position) => {
                        const { latitude, longitude } = position.coords;
                        console.log('User Location:', latitude, longitude); // Debugging log
                        setUserLocation([longitude, latitude]);

                        // Create a marker for user location
                        new mapboxgl.Marker({ color: 'blue' })
                            .setLngLat([longitude, latitude])
                            .setPopup(new mapboxgl.Popup().setText('Your Location'))
                            .addTo(mapRef.current);

                        // Center map on user location
                        mapRef.current.setCenter([longitude, latitude]);
                        mapRef.current.zoomTo(14);
                    },
                    (error) => {
                        console.error('Error getting user location:', error);
                        alert('Unable to retrieve your location. Please allow location access to see nearby shops.');
                        // Fallback to a default location (e.g., London)
                        mapRef.current.setCenter([-0.09, 51.505]);
                    },
                    { enableHighAccuracy: true } // Enable high accuracy for precise location
                );
            } else {
                console.error('Geolocation is not available in this browser.');
                alert('Geolocation is not available in this browser. Please use a compatible browser.');
            }
        }
    }, [mapRef]);

    // Handle map style change
    const handleMapStyleChange = (style) => {
        setMapStyle(style);
    };

    return (
        <div className="container">
            {/* Cover and Search Form */}
            <div className="cover">
                <h1>Discover what's out there.</h1>
                <form className="flex-form">
                <label htmlFor="from">
                    <i className="ion-location"></i>
                </label>
                <input
                    type="search"
                    placeholder="Where do you want to go?"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                />
                <input type="submit" value="Search" />
                </form>
            </div>

            {/* Map Style Selector */}
            <div id="layers-switcher">
                <select
                onChange={(e) => handleMapStyleChange(e.target.value)}
                style={{ padding: '5px', fontSize: '16px' }}
                >
                <option value="mapbox://styles/mapbox/streets-v11">🌍 Streets</option>
                <option value="mapbox://styles/mapbox/satellite-v9">🛰 Satellite</option>
                <option value="mapbox://styles/mapbox/outdoors-v11">🏞 Outdoors</option>
                <option value="mapbox://styles/mapbox/light-v10">💡 Light</option>
                <option value="mapbox://styles/mapbox/dark-v10">🌑 Dark</option>
                </select>
            </div>

            {/* Map Container */}
            <div id="map"></div>
            </div>
    );
};

export default MapView;

